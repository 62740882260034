.SocialLink {
	display: inline-flex;
	justify-content: center;
	margin: 5px;
}

.SocialLink a {
	text-decoration: none;
	color: lightgray;
	display: flex;
}

.SocialLink a:hover {
	color: gray;
}

@media (max-width: 800px) {
	.PortraitContainer {
		display: none;
	}

	.AboutContainer h1 {
		margin-top: 30vh;
	}
}